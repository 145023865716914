import React from "react";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import "./checkout.css";

import poweredByStripe from '../images/powered-by-stripe.png';

import { parseOutFirstName } from '../util/utilities';

import axios from 'axios';

class CheckoutForm extends React.Component {

  constructor(props) {
		super(props);

    this.state = {
			name: '',
			email: '',
			username: '',
      password: '',
			country: '',
			username: '',
      blogUrl: '',
			type: '',
      hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
      stripeSubscriptionStatus:'',
      cardIsValid: false,
      disableButton: 'true',
      errors: [],
		};
	}
  
  componentWillMount = () => {
    this.setState({
      uiLoading: false
    });

	};


  handleCardChange = (event) => {
		
    if (event.complete) {
    
        if (!event.error) {
        
          this.setState({
            cardIsValid: true
          }, () => {
            this.checkButtonShouldBeEnabled();            
          });

        } else
        {
          this.setState({
            cardIsValid: false
          }, () => {
           this.checkButtonShouldBeEnabled();            
          });
        }
    } else
    {
      this.setState({
        cardIsValid: false
      }, () => {
        this.checkButtonShouldBeEnabled();            
      });
    }
  
	};

  checkButtonShouldBeEnabled = () =>
  {
      if (this.state.name.trim() != '' && 
          this.state.email.trim() != '' && 
          this.state.blogUrl.trim() != '' && 
          this.state.type.trim() != '' && 
          this.state.password.trim() != '' && 
          this.state.cardIsValid)
      {
        
        this.setState({
          disableButton: ''
        });
      }else
      {
        this.setState({
          disableButton: 'true'
        });
      }

  }

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.checkButtonShouldBeEnabled();            
    });

	};

  handleSubmit = async event => {

    event.preventDefault();

    const targetAction = this.props.targetAction;

    const stripeCustomerId = this.state.stripeCustomerId;

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {

      return;

    }
    
    const card = elements.getElement(CardElement);

    if (this.state.name.trim() != '' && 
        this.state.email.trim() != '' && 
        this.state.blogUrl.trim() != '' && 
        this.state.type.trim() != '' && 
        this.state.password.trim() != '' && 
       this.state.cardIsValid)
    {

      this.setState({
        disableButton: 'true'
      });

      const name = this.state.name;
      const email = this.state.email;
      const blogUrl = this.state.blogUrl;
      const type = this.state.type;
      const password = this.state.password;
      const me = this;
      
      stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: this.state.name,
        },
      })
      .then(function(result) {
      
          const paymentMethodId = result.paymentMethod.id;
          
          //************* SIGN UP ******************/
          const firstName= parseOutFirstName(name);
          
          const newUserData = {
            firstName: firstName,
            name: name,
            blogUrl: blogUrl,
            type: type,
            email:email,
            password: password,
            paymentMethodId: paymentMethodId
          };
          
          axios
            .post('/api/signupWithPayment', newUserData)
            .then((response) => {
                 
              axios
              .post('/api/initiateActivation', newUserData)
              .then((response) => {
            
                localStorage.setItem('ActivationFirstName', `${newUserData.firstName}`);
                localStorage.setItem('ActivationEmail', `${newUserData.email}`);
      
                window.location.href = '/activate';
                //me.props.history.push('/activate');
            
              })
              .catch((error) => {
                
                console.log("Error sending email:"+error);
              });  
      
            })
            .catch((error) => {
              console.log("Error processing:"+error);
              me.setState({
                errors: error.response.data,
                disableButton: ''
                
              });
            });
          
          //************* SIGN UP end **************/

      }).catch(function(err) {
        alert("Error processing. Try again later:"+err);
        window.location.reload();
                          
        console.log(err);
      });

    } else {

      this.setState({
        disableButton: 'true'
      });

      alert("Please enter all required fields: Name, Email and Credit Card");
    }


  };

  
  render() {

    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fc0d1b",
          iconColor: "#fc0d1b",
        },
      },
    };

    const { errors } = this.state;
    return (

      <div>

      {/*   <form onSubmit={handleSubmit}> */}
      <form onSubmit={this.handleSubmit}>

        <div className="sr-combo-inputsX" style={{maxWidth:"600px"}}>
          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="name"
              name="name" 
              value= {this.state.name}
              placeholder="Name *"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px"}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.name} </div>
          
          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="email"
              name="email"
              value= {this.state.email}
              placeholder="Email *"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px"}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.email} </div>

          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="blogUrl"
              name="blogUrl"
              value= {this.state.blogUrl}
              placeholder="Blog URL *"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px"}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.blogUrl} </div>

          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="type"
              name="type"
              value= {this.state.type}
              placeholder="Blog Type (i.e. weight-loss, blogging, fashion) *"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px"}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.type} </div>

          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="password"
              id="password"
              name="password"
              value= {this.state.password}
              placeholder="Password *"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px"}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.password} </div>

          <div className="sr-combo-inputs-rowx" style={{paddingBottom:"10px"}}>
            <CardElement
              className="sr-input sr-card-element"
              options={options} 
              disabled={this.state.uiLoading} 
              style={{borderRadius:"4px"}} 
              onChange={this.handleCardChange}
            />
          </div>

          <div style={{color:'#000000',fontWeight:'bold'}}>
          15-day free trial. No obligation. No commitments.
          </div>
          <br></br>

          <div style={{color:'#ff642d',fontWeight:'bold'}}>
          * You will not be charged anything during the trial period.<br/>
          &nbsp; If you don't like the service just cancel it at any time.<br/>
          &nbsp; No contracts, no long term commitments ** RISK FREE **
          </div>

        </div>

      <button id="submitButton" name="submitButton" className="btn" disabled={this.state.disableButton} style={{width:"100%",maxWidth:"600px",fontSize:"17px"}}>

      Sign Up

        </button>

      </form>
      <br></br>

    <img src={poweredByStripe} style={{height:"80px"}}/>
    <br></br>

    <div className="banner">
        <span style={{marginTop:"10px",marginBottom:"5px"}}>

          We don't save your payment information, your payment is handled directly my our payment processor. We use <strong>Stripe</strong> as our payment processor, which adheres to the standards set by <strong>PCI-DSS</strong> as managed by the <strong>PCI</strong> Security Standards Council. You can read about Stripe's PCI-DSS compliance <a href="https://stripe.com/guides/pci-compliance" target="_blank">here</a>. 

        </span>
    </div>

      </div>

    );

  }

}


export default function InjectedCheckoutForm(props) {

  return (

    <ElementsConsumer>

      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} targetAction={props.targetAction}/>

      )}

    </ElementsConsumer>

  );

}
