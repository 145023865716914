import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import SubscriptionPayment from './subscriptionPayment';
import SubscriptionDetails from './subscriptionDetails';

import axios from 'axios';
import { authMiddleWare } from '../util/auth';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class subscription extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			country: '',
			blogUrl: '',
			type: '',
			hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
			stripePaymentMethodId:'',
			uiLoading: true,
			buttonLoading: false,
			imageError: ''
		};

	}

	componentWillMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				//console.log(response.data);
				this.setState({
					name: response.data.userCredentials.name,
					email: response.data.userCredentials.email,
					phoneNumber: response.data.userCredentials.phoneNumber,
					country: response.data.userCredentials.country,
					blogUrl: response.data.userCredentials.blogUrl,
					type: response.data.userCredentials.type,
					hasSubscription: response.data.userCredentials.hasSubscription,
					stripeCustomerId: response.data.userCredentials.stripeCustomerId,
					stripeSubscriptionId: response.data.userCredentials.stripeSubscriptionId,	
					stripeSubscriptionStatus: response.data.userCredentials.stripeSubscriptionStatus,
					uiLoading: false
				});

				if (response.data.userCredentials.stripeSubscriptionStatus ==='canceled' || response.data.userCredentials.stripeSubscriptionStatus ==='unpaid' )
					{
						this.setState({
							hasSubscription: false
						});
					}
			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});

	};

	render() {

		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />

					{ !this.state.hasSubscription && <SubscriptionPayment parentState={this.state}/>}
					{ this.state.hasSubscription && <SubscriptionDetails parentState={this.state}/>}
				</main>
			);
		}
	}
}


export default withStyles(styles)(subscription);
