import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

import { authMiddleWare } from '../util/auth';

import appLogo from '../images/logo-nocircle.png';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#ff642d'
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	},
	
});

class login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			errors: [],
			loading: false
		};
	}

	componentWillMount = () => {

	try
	{
		console.log("About to check logged in user.");

		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/alreadyLoggedInCheck')
			.then((response) => {

				//console.log(JSON.stringify(response));

				if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
				{
					this.props.history.push('/dashboard');

				} else
				{
					alert('Error logging in. Please try again later...');
				}

			})
			.catch((error) => {
				//console.log(error);
				if(error.response.status === 403) {
					//Do nothing, let this page render to allow user to log in
				}
				
			});

		} catch(err)
		{
			console.log("Error checking logged in user:"+err);
		
		}
	}

	componentWillReceiveProps(nextProps) {
		try
		{
			if (nextProps && nextProps.UI && nextProps.UI.errors) {
				this.setState({
					errors: nextProps.UI.errors
				});
			}
		} catch(err)
		{
			console.log("Error:"+err);
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		localStorage.removeItem('menuItem')

		this.setState({ loading: true });
		const userData = {
			email: this.state.email,
			password: this.state.password
		};
		axios
			.post('/api/login', userData)
			.then((response) => {
				localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
					
				//------ Update last login date
				const authToken = localStorage.getItem('AuthToken');
                axios.defaults.headers.common = { Authorization: `${authToken}` };
                axios
                  .post('/api/user', {
                    lastLoginDate: new Date().toISOString()
                  })
                  .then((response) => {

					this.setState({ 
						loading: false,
					});	
                    this.props.history.push('/dashboard');
                   
                  })
                  .catch((error) => {
                    console.log('Error in updating customer record:'+error);

					this.setState({ 
						loading: false,
					});	
					this.props.history.push('/dashboard');  //I still want to redirect the page, it is not important to update the last logged in date
            
                  });
				//------
			})
			.catch((error) => {		
				//alert(error);		
				this.setState({
					errors: error.response.data,
					loading: false
				});
			});
	};

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#ff642d' //your color
			  }
			}
		  });

		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/>
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#ff642d',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
						Traffic Infuser
					</div>
					
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>

					<Typography component="h1" variant="h5">
						Login
					</Typography>
					<form className={classes.form} noValidate>
						<ThemeProvider theme={theme}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							type="regular"
							name="email"
							autoComplete="email"
							autoFocus
							helperText={errors.email}
							error={errors.email ? true : false}
							onChange={this.handleChange}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							helperText={errors.password}
							error={errors.password ? true : false}
							onChange={this.handleChange}
						/>

						<div style={{textAlign: 'right'}}>
						
							<Link href="reset" variant="body2">
								Forgot Password?
							</Link>
						
						</div>
			
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							style={{color:'#FFFFFF'}}
							className={classes.submit}
							onClick={this.handleSubmit}
							disabled={loading || !this.state.email || !this.state.password}
						>
							Log In
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
						<Grid container>
							<Grid item>
									<Link href="signup" variant="body2">
										Don't have an account? <strong>Sign Up</strong>
									</Link>
							</Grid>
						</Grid>
						{errors.general && (
							<Typography variant="body2" className={classes.customError}>
								{errors.general}
							</Typography>
						)}
						</ThemeProvider>
					</form>
				</div>
			</Container>
		);
	}
}

export default withStyles(styles)(login);
