import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import login from './pages/login';

//import signup from './pages/signup';     //This is if I want sign up without payment
import signup from './pages/signupWithPayment';  //This signup requires payment

import reset from './pages/reset';
import activate from './pages/activate';
import home from './pages/home';
import main from './pages/main';
import admin from './pages/admin';

const reload = () => window.location.reload();

function App() {
  return (
    <Router>
      <div>
        <Switch>
            <Route exact path="/login" component={login}/>
            <Route exact path="/signup" component={signup}/>
            <Route exact path="/reset" component={reset}/>
            <Route exact path="/activate" component={activate}/>
            <Route exact path="/admin" component={admin}/>
            <Route exact path="/dashboard" component={home}/>
            <Route exact path="/" component={main}/>
            <Route exact path="/index.html" component={main}/>

            <Route path="/about-us.html" onEnter={reload} />
            <Route path="/contact-us.html" onEnter={reload} />
            <Route path="/pricing.html" onEnter={reload} />
            <Route path="/privacy-policy.html" onEnter={reload} />
            <Route path="/security.html" onEnter={reload} />

        </Switch>
      </div>
    </Router>
  );
}
export default App;