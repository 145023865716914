import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;

  let isConfirm = true;

  if (onConfirm)
  {
    isConfirm = true;

  }else
  {
    isConfirm = false;
  }
  
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>

      {isConfirm && (
        <div>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            style={{backgroundColor:'#ff642d'}}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="default"
          >
            Yes
          </Button>
          </div>
       )}

       {!isConfirm && (
        <div>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            style={{backgroundColor:'#ff642d'}}
          >
            OK
          </Button>
         
          </div>
       )}

      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;