import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardContent} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { authMiddleWare } from '../util/auth';
import axios from 'axios';

import checkmark from '../images/checkmark.png';

import visaLogo from  '../images/visa.png';
import discoverLogo from  '../images/discover.png';
import mastercardLogo from  '../images/mastercard.png';
import americanexpressLogo from  '../images/american-express.png';

import ConfirmDialog  from './reusableConfirmDialog';

import { capitalizeWord } from '../util/utilities';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CheckoutForm from "./checkoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const { stripePublishablecKey } = require('../util/constants');

const stripePromise = loadStripe(stripePublishablecKey);

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class subscriptionDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.parentState.name,
			email: this.props.parentState.email,
			phoneNumber: this.props.parentState.phoneNumber,
			country: this.props.parentState.country,
			blogUrl: this.props.parentState.blogUrl,
			type: this.props.parentState.type,
			hasSubscription: this.props.parentState.hasSubscription,
			stripeCustomerId: this.props.parentState.stripeCustomerId,
			stripeSubscriptionId: this.props.parentState.stripeSubscriptionId,
			uiLoading: false,
			confirmOpen: false,
			paymentMethod: {},
			openEditPaymentDialog : false,
			disableButton: ''
		};

	}

	componentWillMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post('/api/subscription', {
				subscriptionId: this.state.stripeSubscriptionId
			  })
			.then((response) => {
				//alert(response.data);

				const subscriptionSatus = response.data.status;

				axios
				.post('/api/getCustomerPaymentMethod', {
					stripeCustomerId: this.state.stripeCustomerId
				})
				.then((responsePaymentMethod) => {
			
					const paymentMethod = responsePaymentMethod.data;

				//	console.log("PAYMENT METHOD:");
					console.log(JSON.stringify(paymentMethod));
				//	alert(JSON.stringify(paymentMethod));

					
					this.setState({
						stripeSubscriptionStatus: subscriptionSatus,
						paymentMethod:paymentMethod,	
						uiLoading: false
					});
	
					if (subscriptionSatus === 'canceled')
					{
						this.setState({
							disableButton: 'true'
						});
					}
					
				})
				.catch((error) => {
					alert("An error occurred while retrieving payment method:"+error);
					console.log(error);
				});

			})
			.catch((error) => {
				alert("An error occurred while retrieving subscription:"+error);
				console.log(error);
			});

	};


	
	closePaymentDialog = () => {

		this.setState({ openEditPaymentDialog: false});
	}

	showEditPaymentDialog = (flag) => {

		this.setState({ openEditPaymentDialog: flag });
	}

	setConfirmOpen = (flag) => {

		this.setState({ confirmOpen: flag });
	}

	cancelSubscription = () => {

		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post('/api/cancelSubscription', {
				subscriptionId: this.state.stripeSubscriptionId
			  })
			.then((response) => {
				//alert(response.data);
				this.setState({
					stripeSubscriptionStatus: 'cancelled',
					disableButton: 'true'	
				});

				 //Call backend code to update user's hasSubscription flag
				 const authToken = localStorage.getItem('AuthToken');
				 axios.defaults.headers.common = { Authorization: `${authToken}` };
				 axios
				   .post('/api/user', {
					 hasSubscription: false
				   })
				   .then((response) => {

					 //alert("Updated record successfully");
					 
					 window.location.reload(false);

				   })
				   .catch((error) => {
					 alert("Error cancelling your subscription. Try again later.");
					 window.location.reload(false);

					 console.log('Error in updating customer record:'+error);
			 
				   });


			})
			.catch((error) => {
				alert("An error occurred while retrieving subscription:"+error);
				console.log(error);
			});

	}


	render() {
		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<div>

					<h1>Your Subscriptions</h1>
                    <p>Manage your subscriptions.</p>
					<br />
			
					<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Card className={classes.root} variant="outlined">
									<CardContent>
										<Typography variant="h5" component="h2">
											<span style={{color:"#ff642d",fontWeight:"500"}}>Social Media Traffic Subscription</span>
										</Typography>
										<Typography variant="body2" component="p">
										<img src={checkmark} style={{height:"18px"}}/> Facebook Traffic  &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> Quora Traffic &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> Reddit Traffic
          									<br></br><br></br>
										</Typography>
										<Typography className={classes.pos} color="textSecondary">
											
											<div>
												<strong>Frequency:</strong> Monthly
											</div>

											<div style={{paddingTop:"6px"}}>
												<strong>Status:</strong> <span style={{color:"#ff642d",fontSize:"18px",fontWeight:"bold"}}>{capitalizeWord(this.state.stripeSubscriptionStatus)}</span>
											</div>

											<div style={{paddingTop:"6px"}}>
												<strong>Price:</strong> <span style={{color:"#000000",fontSize:"18px",fontWeight:"bold"}}>
													
												{(this.state.stripeSubscriptionStatus !=='trialing') && (
													<span>
													$19.97
													</span>

												)}

												{(this.state.stripeSubscriptionStatus ==='trialing') && (
													<span>
													$0   <span style={{color:'#7c7c7c', fontSize:'14px'}}> &nbsp; <i>($19.97 after trial period ends)</i></span>
													</span>

												)}
													
													</span>
											</div>

											<div style={{paddingTop:"6px"}}>
												<strong>Payment Method:</strong> 
												<br></br>

												{this.state.paymentMethod.card && 
													<div style={{border:'1px solid #000000'}}>
														<table>
															<tr>
																<td style={{verticalAlign:"top",color:"#000000",fontSize:"16px",fontWeight:"bold"}}>
																	{capitalizeWord(this.state.paymentMethod.type)}....{this.state.paymentMethod.card.last4}
																	<br></br>
																	{this.state.paymentMethod.billing_details.name}
																</td>
																<td style={{verticalAlign:"top"}}>
																	<div style={{paddingLeft:'50px'}}>
																		{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='visa' && <img src={visaLogo}/>}
																		{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='amex' && <img src={americanexpressLogo}/>}
																		{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='discover' && <img src={discoverLogo}/>}
																		{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='mastercard' && <img src={mastercardLogo}/>}
																
																	</div>
																</td>
																<td style={{verticalAlign:'top',paddingLeft:'20px',paddingTop:'3px'}}>
																	<Button variant="contained" style={{backgroundColor:"#ff642d",height:"25px", color:"#FFFFFF"}}  onClick={() => this.showEditPaymentDialog(true)}>Edit</Button>
																</td>
															</tr>

														</table>
													</div>
												}
											</div>

										</Typography>
									</CardContent>
									<CardActions>
                      
										<Button disabled={ this.state.disableButton ? "true" : "" } variant="contained" style={{color:"#838080"}} onClick={() => this.setConfirmOpen(true)}>Cancel Subscription</Button>

									</CardActions>	
								</Card>		
							</Grid>
					</Grid>	

					<ConfirmDialog
						title="Cancel Subscription?"
						open={this.state.confirmOpen}
						setOpen={this.setConfirmOpen}
						onConfirm={this.cancelSubscription}
					>
						Are you sure you want to cancel this subscription?
					</ConfirmDialog>

					{/*  EDIT PAYMENT METHOD DIALOG  */}	
					<Dialog open={this.state.openEditPaymentDialog} onClose={this.closePaymentDialog} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Edit Payment Method</DialogTitle>
						<DialogContent>
						
						<Elements stripe={stripePromise}>

							<CheckoutForm targetAction="EditPaymentMethod"/>

						</Elements>	
												
						</DialogContent>
						<DialogActions>
						<Button onClick={this.closePaymentDialog} color="primary">
							Cancel
						</Button>
					
						</DialogActions>
					</Dialog>						

			 	</div>
			);
		}
	}
}


export default withStyles(styles)(subscriptionDetails);
