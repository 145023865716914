import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";

import { authMiddleWare } from '../util/auth';

const { stripePublishablecKey } = require('../util/constants');

const stripePromise = loadStripe(stripePublishablecKey);

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class subscriptionDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.parentState.name,
			email: this.props.parentState.email,
			phoneNumber: this.props.parentState.phoneNumber,
			country: this.props.parentState.country,
		    blogUrl: this.props.parentState.blogUrl,
			type: this.props.parentState.type,
			hasSubscription: this.props.parentState.hasSubscription,
			stripeCustomerId: this.props.parentState.stripeCustomerId,
			stripeSubscriptionId: this.props.parentState.stripeSubscriptionId
		};
	}

	componentWillMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		
		this.setState({
			uiLoading: false //Set once you load data successfully
		});

	};

	render() {
		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<div>

					<h1>Subscriptions</h1>
					<br />
			
			 		

					 <Elements stripe={stripePromise}>

						<CheckoutForm targetAction="Subscribe"/>

					</Elements>

			 	</div>
			
			);
		}
	}
}


export default withStyles(styles)(subscriptionDetails);
