import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardActions, CardContent, Divider, Button, Grid, TextField } from '@material-ui/core';

import clsx from 'clsx';

import axios from 'axios';
import { authMiddleWare } from '../util/auth';
import { parseOutFirstName } from '../util/utilities';

import ConfirmDialog  from '../components/reusableConfirmDialog';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class account extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			country: '',
		    blogUrl: '',
			type: '',
			alertOpen: false,
			alertMessage: '',
			alertTitle:'',
			uiLoading: true,
			buttonLoading: false,
			imageError: ''
		};
	}

	setAlertOpen = (flag) => {

		this.setState({ alertOpen: flag });
	}
	
	componentWillMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				console.log(response.data);
				this.setState({
					name: response.data.userCredentials.name,
					email: response.data.userCredentials.email,
					phoneNumber: response.data.userCredentials.phoneNumber,
					country: response.data.userCredentials.country,
				    blogUrl: response.data.userCredentials.blogUrl,
					type: response.data.userCredentials.type,
					uiLoading: false
				});
			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};



	updateFormValues = (event) => {
		event.preventDefault();
		this.setState({ buttonLoading: true });
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };

		const firstName= parseOutFirstName(this.state.name);
		this.setState({ firstName: firstName });

		const formRequest = {
			firstName: firstName,
			name: this.state.name,
			country: this.state.country,
            phoneNumber: this.state.phoneNumber,
			type: this.state.type,
            blogUrl : this.state.blogUrl
		};
		axios
			.post('/api/user', formRequest)
			.then(() => {
				this.setState({ buttonLoading: false });

				this.setState({
					alertTitle: 'Successful',
					alertMessage: 'Your account details have been successfully updated.', 
					alertOpen: true
				});	
			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
				this.setState({
					buttonLoading: false
				});
			});
	};

	render() {
		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<Card {...rest} className={clsx(classes.root, classes)}>
						<CardContent>
							<div className={classes.details}>
								<div>
									<Typography className={classes.locationText} gutterBottom variant="h4">
										{this.state.name} 
									</Typography>
								</div>
							</div>
							<div className={classes.progress} />
						</CardContent>
						<Divider />
					</Card>

					<br />
					<Card {...rest} className={clsx(classes.root, classes)}>
						<form autoComplete="off" noValidate>
							<Divider />
							<CardContent>
								<Grid container spacing={3}>
									<Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Name"
											margin="dense"
											name="name"
											type="regular"
											variant="outlined"
											value={this.state.name}
											onChange={this.handleChange}
										/>
									</Grid>
						            <Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Country"
											margin="dense"
											name="country"
											type="regular"
											variant="outlined"
											value={this.state.country}
											onChange={this.handleChange}
										/>
									</Grid>
                                    <Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Phone Number"
											margin="dense"
											name="phoneNumber"
											type="regular"
											variant="outlined"
											value={this.state.phoneNumber}
											onChange={this.handleChange}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Blog URL"
											margin="dense"
											name="blogUrl"
											type="regular"
											variant="outlined"
											disabled={true}
											value={this.state.blogUrl}
											onChange={this.handleChange}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Type"
											margin="dense"
											name="type"
											type="regular"
											variant="outlined"
											disabled={true}
											value={this.state.type}
											onChange={this.handleChange}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Email"
											margin="dense"
											name="email"
											type="regular"
											variant="outlined"
											disabled={true}
											value={this.state.email}
											onChange={this.handleChange}
										/>
									</Grid>
									
								</Grid>
							</CardContent>
							<Divider />
							<CardActions />
						</form>
					</Card>

					<Button
						color="primary"
						variant="contained"
						type="submit" 
						style={{color:'#FFFFFF'}}
						className={classes.submitButton}
						onClick={this.updateFormValues}
						disabled={
							this.state.buttonLoading ||
							!this.state.name ||
						    !this.state.blogUrl ||
							!this.state.country
						}
					>
						Save details
						{this.state.buttonLoading && <CircularProgress size={30} className={classes.progess} />}
					</Button>

					<div>
						<br></br>
						** If you need to update your email, blog URL or blog type please open a support ticket.
					</div>
					
					<ConfirmDialog
						title={this.state.alertTitle}
						open={this.state.alertOpen}
						setOpen={this.setAlertOpen}
					>
						{this.state.alertMessage}
					</ConfirmDialog>
				</main>
			);
		}
	}
}


export default withStyles(styles)(account);
