import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { authMiddleWare } from '../util/auth';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import facebookLogo from '../images/facebook.png';
import quoraLogo from '../images/quora.png';
import redditLogo from '../images/reddit.png';
import stackexchangeLogo from '../images/stackexchange.png';
import twitterLogo from '../images/twitter.png';
import youtubeLogo from '../images/youtube.png';

import "./blur.css";

import mockedSocialItem from '../images/mocked-social-item.png';

import { withMediaQuery } from '../pages/withMediaQuery';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	submitButton: {
		display: 'block',
		color: 'white',
		textAlign: 'center',
		position: 'absolute',
		top: 14,
		right: 10
	},
	floatingButton: {
		position: 'fixed',
		bottom: 0,
		right: 0
	},
	form: {
		width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	pos: {
		marginBottom: 12
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	dialogeStyle: {
		maxWidth: '50%'
	},
	viewRoot: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},	
	mockedItem: {
		width:'100%',
		height: '107px',
		backgroundImage: 'url(' + mockedSocialItem +')',
  		backgroundRepeat: 'no-repeat'
	  },
});

/* Remove later since the user will not have the option to update/edit matches*/
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class match extends Component {
	constructor(props) {
		super(props);

		this.state = {
			matches: '',
			hasSubscription: false,
			errors: [],
			facebookGroups: [],
			open: false,
			uiLoading: true,
			buttonType: '',
			viewOpen: false,
			showAnswerdOnly:this.stringToBoolean(localStorage.getItem('showAnswerdOnly'))
		};


	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	componentWillMount = () => {

		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/matches')
			.then((response) => {
				this.setState({
					matches: response.data,
					uiLoading: false
				});			

				var facebookCount = response.data.filter(match =>(match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="facebook").length;
				var quoraCount = response.data.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="quora").length;
				var redditCount = response.data.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="reddit").length;
				var twitterCount = response.data.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="twitter").length;
				var youtubeCount = response.data.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="youtube").length;
				var stackexchangeCount = response.data.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="stackexchange").length;

				this.setState({
					facebookCount: facebookCount,
					quoraCount: quoraCount,
					redditCount: redditCount,
					twitterCount: twitterCount,
					youtubeCount: youtubeCount,
					stackexchangeCount: stackexchangeCount
				});	

			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
			});


		//Also load user data
		axios
		.get('/api/user')
		.then((response) => {
			//console.log(response.data);
			this.setState({
				hasSubscription: response.data.userCredentials.hasSubscription,
				stripeSubscriptionStatus: response.data.userCredentials.stripeSubscriptionStatus,
				facebookGroups: response.data.userCredentials.facebookGroups
			});

			//If stripe subscription is canceled or unpaid set hasSubscription to false to so that we can limit the access to the records on the page

			//Use this one if we want limit functionality during trialing:
			//if (response.data.userCredentials.stripeSubscriptionStatus ==='canceled' || response.data.userCredentials.stripeSubscriptionStatus ==='unpaid' || response.data.userCredentials.stripeSubscriptionStatus ==='trialing' )  
		
			//Use this one if we want grant full access during trialing:
			if (response.data.userCredentials.stripeSubscriptionStatus ==='canceled' || response.data.userCredentials.stripeSubscriptionStatus ==='unpaid' ) 	
			{
				this.setState({
					hasSubscription: false
				});

			} 
			
			if (response.data.userCredentials.exemptFlag) //If this is an exempt user then let user have full access
			{
				this.setState({
					hasSubscription: true
				});
			}

		
		})
		.catch((error) => { 
			if(error && error.response && error.response.status && error.response.status === 403) {
				window.location.reload();
			}
			console.log(error);
			this.setState({ errorMsg: 'Error in retrieving the data' });
		});	
	};

	stringToBoolean(string){
		if (string != null)
		{
			switch(string){
				case "true": case "yes": case "1": return true;
				case "false": case "no": case "0": case null: return false;
				default: return Boolean(string);
			}
		} else
		{
			return false;
		}
	}

	handleOpenUrl(data) {		
        window.open(data.match.questionUrl);
	}

	handleShowAnswerdOnlyChange(event)
	{
		//alert("handleShowAnswerdOnlyChange:"+event.target.checked);

		localStorage.setItem('showAnswerdOnly', event.target.checked);
		this.state.showAnswerdOnly = event.target.checked;
		this.setState({"showAnswerdOnly":this.state.showAnswerdOnly});
			

		var facebookCount = this.state.matches.filter(match =>(match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="facebook").length;
		var quoraCount = this.state.matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="quora").length;
		var redditCount = this.state.matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="reddit").length;
		var twitterCount = this.state.matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="twitter").length;
		var youtubeCount = this.state.matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="youtube").length;
		var stackexchangeCount = this.state.matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source=="stackexchange").length;

		this.setState({
			facebookCount: facebookCount,
			quoraCount: quoraCount,
			redditCount: redditCount,
			twitterCount: twitterCount,
			youtubeCount: youtubeCount,
			stackexchangeCount: stackexchangeCount
		});	
	
		
	}


	handleAlreadyAnsweredChange(event,match)
	{
		//alert("handleAlreadyAnsweredChange:"+event.target.checked);
		
		match.answered = event.target.checked;
		this.setState({"matches":this.state.matches});
		this.updateMatchAnswered(event,match,event.target.checked);
	}

	updateMatchAnswered (event,match,answered)
	{
		console.log("About to updateMatchAnswered");

		authMiddleWare(this.props.history);
		//event.preventDefault();
		const userMatch = {
			answered: answered,
			answeredDate: new Date().toISOString()
		};
		let options = {};
		options = {
			url: `/api/match/${match.matchId}`,
			method: 'put',
			data: userMatch
		};
		
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then((response) => {
				if (response.data)
				{
					console.log("Sucessfully update:"+JSON.stringify(response.data));
				} else
				{
					console.log("Sucessfully update:"+JSON.stringify(response));
				}
				//alert("Sucessfully update");
			})
			.catch((error) => {
				//this.setState({ open: true, errors: error.response.data });
				console.log(error);
				//alert(error);
			});
	};


	fixLongUrl(url)
	{
		//url = url.replaceAll("/","/ ");
		//url = url.replaceAll("/ / ","//");

		url = url.replace(new RegExp("/", 'g'), "/ ");
		url = url.replace(new RegExp("/ / ", 'g'), "//");

		return url;
	}

	gotoSubscribePage = (event) => {
		localStorage.setItem('menuItem', 4);
		window.location.reload(false);
	};

	render() {

		const DialogTitle = withStyles(styles)((props) => {
			const { children, classes, onClose, ...other } = props;
			return (
				<MuiDialogTitle disableTypography className={classes.root} {...other}>
					<Typography variant="h6">{children}</Typography>
					{onClose ? (
						<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
							<CloseIcon />
						</IconButton>
					) : null}
				</MuiDialogTitle>
			);
		});


		const MatchList = withStyles(styles)((props) => {

			const { matches,source, backgroundColor, ...other } = props;

			return (
					<Grid container spacing={2} style={{maxWidth:this.props.isDesktop ? "100%" : "250px"}}>
							
					{matches.filter(match => (match.answered == !this.state.showAnswerdOnly || match.answered == false) && match.source==source).map((match,index) => (	
						<Grid item xs={12} sm={6}>
							<Card className={classes.root} variant="outlined" style={{borderColor:'#6d6d6d'}}>
								<CardContent  style={{padding: "0px",margin:"0px"}}>
									<Typography className={classes.pos} color="textPrimary" style={{backgroundColor: backgroundColor,paddingLeft: "5px",color:"#FFFFFF",paddingRight: "5px"}}>
										<b>Social Media Question:</b>
										<br/>
										{/*}
										matchPercentage:
										{match.matchPercentage}
										<br/>
										commonWords:
										{match.commonWords}
										*/}
									</Typography>

									{/* Actual item showing actual social media item */}
									{(index <= 1 || this.state.hasSubscription) && (
									<div>	
										<Typography variant="h8" component="h2" style={{paddingLeft: "5px",paddingRight: "5px"}}>
											{match.question}
										</Typography>
										<Typography className={classes.pos} color="textSecondary" style={{paddingLeft: "5px",paddingRight: "5px"}}>
											{dayjs(match.questionPostDate).fromNow()}
										</Typography>
										<Typography variant="body2" component="p" style={{paddingLeft: "5px",paddingRight: "5px"}}>
											{this.fixLongUrl(match.questionUrl)}

											<IconButton  onClick={() => this.handleOpenUrl({ match })}>
												<OpenInNewOutlinedIcon/>
											</IconButton>

										</Typography>
									</div>
									)}

									{/* Mocked item showing fake social media item when user is not subscribed*/}	
									{!this.state.hasSubscription && index > 1 && (
									<div className={classes.mockedItem}>	
									
										<div class="blur-background" style={{textAlign:'center', fontSize:'18px',paddingTop:'20px'}}>

											{this.state.stripeSubscriptionStatus !=='unpaid' && this.state.stripeSubscriptionStatus !=='trialing' && (					
												<Button color="primary" variant="contained" style={{color:'#FFFFFF'}} onClick={this.gotoSubscribePage}>
													Unlock by subscribing
												</Button>
											)}

											{this.state.stripeSubscriptionStatus ==='trialing' && (					
												<Button color="primary" variant="contained" style={{color:'#FFFFFF'}} onClick={this.gotoSubscribePage}>
													Unlock by upgrading
												</Button>
											)}

											{this.state.stripeSubscriptionStatus ==='unpaid' && (					
												<Button color="primary" variant="contained" style={{color:'#FFFFFF'}} onClick={this.gotoSubscribePage}>
													Update your payment method
												</Button>
											)}

										</div>

									</div>
									)}

									<Typography className={classes.pos} color="textPrimary" style={{backgroundColor: "#dfdfdf",paddingLeft: "5px",paddingRight: "5px"}}>
										<b>Your Post:</b> <span style={{fontSize:"14px"}}>This post answers or is related to the question above</span>
									</Typography>
									<Typography variant="h8" component="h2" style={{paddingLeft: "5px",paddingRight: "5px"}}>
										{match.postTitle}
									</Typography>
									<Typography variant="body2" component="p" style={{paddingLeft: "5px",paddingRight: "5px"}}>
										{this.fixLongUrl(match.postUrl)}
									

									<IconButton  onClick={() => {navigator.clipboard.writeText(match.postUrl)}}>
										<FileCopyOutlinedIcon/>
									</IconButton>

									</Typography>
								</CardContent>

								<CardActions style={{backgroundColor: "#dfdfdf"}}>

									<FormControlLabel id="alreadyAnsweredCheck" control={<Checkbox style={{color:"#000000"}} disabled={(index > 1 && !this.state.hasSubscription)?"true":""} checked={match.answered} onChange={(event) =>{this.handleAlreadyAnsweredChange(event,match)}} />} label={<span style={{ color: '#000000' }}>I already answered this question</span>}/>

								</CardActions>
								
							</Card>
						
						</Grid>
					))}
				</Grid>
			);
		});

		const DialogContent = withStyles((theme) => ({
			viewRoot: {
				padding: theme.spacing(2)
			}
		}))(MuiDialogContent);

		dayjs.extend(relativeTime);
		const { classes } = this.props;
		const { open, errors, viewOpen } = this.state;

		
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />

                    <h1>Your Matches</h1>
                    <p>These are the social media questions that are answered or are related to your blog posts.</p>
					<p>
						<FormControlLabel id="showAnswerdOnlyCheck" control={<Checkbox checked={this.state.showAnswerdOnly} onChange={(event) =>{this.handleShowAnswerdOnlyChange(event)}} />} label="Only show question that have not been answered."/>

					</p>


					{/* ACCORDION BEGIN*/}

					<div>
					<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={facebookLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>Facebook</span>
								<span>&nbsp; ({this.state.facebookCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<div>
									Facebook groups you need to join: &nbsp;
									
									{this.state.facebookGroups.map((facebookGroup) => (
										<span><a href={facebookGroup.groupUrl} target="_blank">{facebookGroup.groupName}</a> &nbsp; | &nbsp; </span>  
									))}
								
								<br/><br/>
								<MatchList matches={this.state.matches} backgroundColor="#385797" source="facebook"/> 

								</div>
							</AccordionDetails>

						</Accordion>
						<br/>
						<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={quoraLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>Quora</span>
								<span>&nbsp; ({this.state.quoraCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<MatchList matches={this.state.matches} backgroundColor="#c41a00" source="quora"/>
							</AccordionDetails>

						</Accordion>
						<br/>
						<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={redditLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>Reddit</span>
								<span>&nbsp; ({this.state.redditCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<MatchList matches={this.state.matches} backgroundColor="#ff3f18" source="reddit"/>
							</AccordionDetails>

						</Accordion>
						<br/>
						<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={twitterLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>Twitter</span>
								<span>&nbsp; ({this.state.twitterCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<MatchList matches={this.state.matches} backgroundColor="#1d9bf0" source="twitter"/>
							</AccordionDetails>

						</Accordion>
						<br/>
						<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={youtubeLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>Youtube</span>
								<span>&nbsp; ({this.state.youtubeCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<MatchList matches={this.state.matches} backgroundColor="#363636" source="youtube"/>
							</AccordionDetails>

						</Accordion>
						<br/>
						<Accordion>

							<AccordionSummary  expandIcon={<ExpandMore />}>
								<img src={stackexchangeLogo}></img> &nbsp; &nbsp; <span style={{fontSize:"16px",fontWeight:"bold"}}>StackExchange</span>
								<span>&nbsp; ({this.state.stackexchangeCount})</span>
							</AccordionSummary>

							<AccordionDetails>
								<MatchList matches={this.state.matches} backgroundColor="#4aa2da" source="stackexchange"/>
							</AccordionDetails>

						</Accordion>

						</div>	

					{/* ACCORDION END */}

				</main>
			);
		}
	}
}

export default withMediaQuery()(withStyles(styles) (match));