exports.parseOutFirstName = (name) => {

	let firstName = '';

	try
	{
		if (name != null && name !='')
		{
			var details=name.split(' ');

			if (details.length>0)
			{
				firstName = details[0];
				if (firstName != null)
				{
					firstName = firstName.trim();
				}
			}
		}

	} catch(err)
	{
		firstName = '';
	}

	return firstName;
};


exports.capitalizeWord = (str) => {

	if (str != null)
	{
		return str.charAt(0).toUpperCase() + str.slice(1);
	} else
	{
		return "";
	}
};
