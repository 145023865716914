import React from "react";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import "./checkout.css";

import checkmark from '../images/checkmark.png';

import poweredByStripe from '../images/powered-by-stripe.png';

import axios from 'axios';
import { authMiddleWare } from '../util/auth';

class CheckoutForm extends React.Component {

  constructor(props) {
		super(props);

    this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			username: '',
			country: '',
			username: '',
      blogUrl: '',
			type: '',
      hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
      stripeSubscriptionStatus:'',
      cardIsValid: false,
      disableButton: 'true'
		};
	}
  
  componentWillMount = () => {
		authMiddleWare(this.props.history);

		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				//console.log(response.data);
				this.setState({
					name: response.data.userCredentials.name,
					email: response.data.userCredentials.email,
					phoneNumber: response.data.userCredentials.phoneNumber,
					country: response.data.userCredentials.country,
          blogUrl: response.data.userCredentials.blogUrl,
					type: response.data.userCredentials.type,
          hasSubscription: response.data.userCredentials.hasSubscription,
					stripeCustomerId: response.data.userCredentials.stripeCustomerId,
					stripeSubscriptionId: response.data.userCredentials.stripeSubscriptionId,
          stripeSubscriptionStatus: response.data.userCredentials.stripeSubscriptionStatus,
					uiLoading: false
				});
      
			})
			.catch((error) => {
		    if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
        console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});

	};


  handleCardChange = (event) => {
		
    if (event.complete) {
    
        if (!event.error) {
        
          this.setState({
            cardIsValid: true
          }, () => {
            this.checkButtonShouldBeEnabled();            
          });

        } else
        {
          this.setState({
            cardIsValid: false
          }, () => {
           this.checkButtonShouldBeEnabled();            
          });
        }
    } else
    {
      this.setState({
        cardIsValid: false
      }, () => {
        this.checkButtonShouldBeEnabled();            
      });
    }
  
	};

  checkButtonShouldBeEnabled = () =>
  {
      if (this.state.name.trim() != '' && 
          this.state.email.trim() != '' && 
          this.state.cardIsValid)
      {
        
        this.setState({
          disableButton: ''
        });
      }else
      {
        this.setState({
          disableButton: 'true'
        });
      }

  }

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.checkButtonShouldBeEnabled();            
    });

	};

  handleSubmit = async event => {

    event.preventDefault();

    const targetAction = this.props.targetAction;

    const stripeCustomerId = this.state.stripeCustomerId;

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {

      return;

    }

    const card = elements.getElement(CardElement);

    if (this.state.name.trim() != '' && 
        this.state.email.trim() != '' && 
       this.state.cardIsValid)
    {

      this.setState({
        disableButton: 'true'
      });

      const name = this.state.name;
      const email = this.state.email;
      const phoneNumber = this.state.phoneNumber;
      const stripeSubscriptionStatus = this.state.stripeSubscriptionStatus;
      authMiddleWare(this.props.history);

      stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: this.state.name,
        },
      })
      .then(function(result) {
      
              const paymentMethodId = result.paymentMethod.id;

    //          alert(paymentMethodId);

              //If it just unpaid then update user default payment method
              if (stripeSubscriptionStatus ==='unpaid' || targetAction==='EditPaymentMethod')
              {

                //Call Backend to update payment method
                const authToken = localStorage.getItem('AuthToken');
                axios.defaults.headers.common = { Authorization: `${authToken}` };
                axios
                  .post('/api/updatePaymentMethod', {
                    customerId: stripeCustomerId,
                    paymentMethodId: paymentMethodId
                  })
                  .then((response) => {

                    window.location.reload();
                   
                  })
                  .catch((error) => {
                    alert("Error occurred. Try again later.");
                    window.location.reload();

                    console.log('Error in updating customer record:'+error);
            
                  });

              } else // Create new subscription
              {

                  //Call Backend to subscribe user
                  const authToken = localStorage.getItem('AuthToken');
                  axios.defaults.headers.common = { Authorization: `${authToken}` };
                  axios
                    .post('/api/subscribe', {
                      name: name,
                      email: email,
                      phoneNumber: phoneNumber,
                      paymentMethodId: paymentMethodId
                    })
                    .then((response) => {
                
                        window.location.reload();
                      
                    })
                    .catch((error) => {
                      alert("Error with your submission. Try again later.");
                      window.location.reload();
                                              
                      console.log('Error in subscribing user:'+error);
                      
                    });

                  } // end if 




      }).catch(function(err) {
        alert("Error processing. Try again later:"+err);
        window.location.reload();
                          
        console.log(err);
      });

    } else {

      this.setState({
        disableButton: 'true'
      });

      alert("Please enter all required fields: Name, Email and Credit Card");
    }


  };

  
  render() {

    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fc0d1b",
          iconColor: "#fc0d1b",
        },
      },
    };

    return (

      <div>

        <div class="product-info">

          <h3 className="product-title" style={{fontSize:"24px",color:"#ff642d"}}>Social Media Traffic Subscription</h3>

          <img src={checkmark} style={{height:"18px"}}/> Facebook Traffic  &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> Quora Traffic &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> Reddit Traffic
          <br></br><br></br>

        </div>

      {/*   <form onSubmit={handleSubmit}> */}
      <form onSubmit={this.handleSubmit}>
        <h1>
          US $19.97 <span style={{fontSize:"17px",fontWeight:"plain"}}>/month</span>
          
        </h1>
        <h4>Monthly Subscription. Cancel Any Time.</h4>

        <div className="sr-combo-inputs" style={{maxWidth:"600px"}}>
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name" 
              value= {this.state.name}
              placeholder="Name"
              autoComplete="cardholder"
              className="sr-input" 
              onChange={this.handleChange}
            />
          </div>

          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="email"
              name="email"
              value= {this.state.email}
              placeholder="Email"
              autoComplete="cardholder"
              className="sr-input" 
              onChange={this.handleChange}
            />
          </div>

          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value= {this.state.phoneNumber}
              placeholder="Phone"
              autoComplete="cardholder"
              className="sr-input" 
              onChange={this.handleChange}
            />
          </div>
          
          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options} 
              onChange={this.handleCardChange}
            />
          </div>
        </div>

{/*
        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn"
          disabled={processing || !clientSecret || !stripe}
        >
          {processing ? "Processing…" : "Pay"}
        </button>
*/}

      <button id="submitButton" name="submitButton" className="btn" disabled={this.state.disableButton} style={{width:"100%",maxWidth:"600px",fontSize:"17px"}}>

        {this.props.targetAction==='EditPaymentMethod'?'Update Payment Method':'Pay'}

        </button>

      </form>
      <br></br>

    <img src={poweredByStripe} style={{height:"80px"}}/>
    <br></br>

    <div className="banner">
        <span style={{marginTop:"10px",marginBottom:"5px"}}>

          We don't save your payment information, your payment is handled directly my our payment processor. We use <strong>Stripe</strong> as our payment processor, which adheres to the standards set by <strong>PCI-DSS</strong> as managed by the <strong>PCI</strong> Security Standards Council. You can read about Stripe's PCI-DSS compliance <a href="https://stripe.com/guides/pci-compliance" target="_blank">here</a>. 

        </span>
    </div>

      </div>

    );

  }

}


export default function InjectedCheckoutForm(props) {

  return (

    <ElementsConsumer>

      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} targetAction={props.targetAction}/>

      )}

    </ElementsConsumer>

  );

}
