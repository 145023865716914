/* This is a simple utility page for me to do admin stuff, it only works for an admin user  */
import React, { Component } from 'react';
import axios from 'axios';

import { authMiddleWare } from '../util/auth'
import { withMediaQuery } from './withMediaQuery';
import withStyles from '@material-ui/core/styles/withStyles';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		display: "flex"
	  },
	  appBar: {
		zIndex: theme.zIndex.drawer + 1
	  },
	  drawer: {
		flexShrink: 0,
		width: drawerWidth
	  },
	  drawerPaper: {
		width: drawerWidth
	  },
	  menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("md")]: {
		  display: "none"
		}
	  },
	  toolbar: {
		...theme.mixins.toolbar,
		[theme.breakpoints.down("sm")]: {
		  display: "none"
		}
	  },
	  content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(1)
	  }
});



class home extends Component {
	state = {
		render: false
	};
	
	
	constructor(props) {
		super(props);

	
		this.state = {
			isAdminUser: '',
			action: '',
			message:'',
			result:''
		};
	}

	

	componentWillMount = () => {

		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				//console.log(response.data);
				this.setState({
					isAdminUser: response.data.userCredentials.isAdminUser		
				});

				if (response.data.userCredentials.isAdminUser)
				{
					const search = this.props.location.search;    
					let action = new URLSearchParams(search).get("action");

					this.setState({
						action: action		
					});

					if (action != null && action !='')
					{
						switch (action) {
							case 'email':
								this.processEmails();
							  break;
							case 'other':
								this.otherAction();
							  break;
							default:
							  console.log("Action not recognized");
						  } 
					}

				}else
				{
					this.setState({
						message: "You don't have the rights to access this page"		
					});

				}


			})
			.catch((error) => {
				console.log(error);
				if(error.response.status === 403) {
					this.props.history.push('/login')
				}
				console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	};

	processEmails = () => {

		
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/users')
			.then((response) => {
			
				// Send activation emails if for whatever reason they were missed
				var users1 = response.data.filter(user =>!user.hasEmailBeenActivated && !user.emailAccountActivationSent);
			
				for (var i=0; i < users1.length; i++) {
					const user1 = users1[i];

						//console.log("Checking:"+user.username);
					
						console.log("------------------------------------");
						console.log(user1.email);
						console.log(user1.hasEmailBeenActivated);
	
						if (!user1.hasEmailBeenActivated)
						{
							console.log("Send Activation Email:"+user1.username);
							this.sendActivationEmail(user1.email,user1.firstName);
	
						} 
	
				}

				//Send setup emails
				var users = response.data.filter(user =>user.hasSubscription && user.stripeSubscriptionStatus != 'canceled' && user.stripeSubscriptionStatus != 'unpaid');
			
				//console.log(JSON.stringify(users));

				for (var i=0; i < users.length; i++) {
					const user = users[i];

					try
					{
						console.log(" --- user ---:");
						console.log(user);
						console.log(" --- user JSON---:");
						console.log(JSON.stringify(user));

						console.log("CHECK 1");
						if (user.hasEmailBeenActivated && user.setup1serverSetupEmailSentDate === '')
						{
							console.log("Send Step 1 Email:"+user.username);
							this.sendStep1Email(user.email);

						} 
						

						//We are just sending two email, 1) Setting up (ingesting posts) 2) Done
						console.log("CHECK 2");
						if (user.hasEmailBeenActivated && user.setup1IngestPostsCompleted && user.setup1serverSetupEmailSentDate !== '' && user.setup2serverSetupEmailSentDate === ''  && user.setup2serverSetupEmailSentDate === '')
						{
							console.log("Send Step 3 Email:"+user.username);
							this.sendStep3Email(user.email);


						}  

						/*
						console.log("CHECK 2");
						if (user.hasEmailBeenActivated && user.setup1IngestPostsCompleted && user.setup1serverSetupEmailSentDate !== '' && user.setup2IdentifyGroupsCompleted && user.setup2serverSetupEmailSentDate === '')
						{
							console.log("Send Step 2 Email:"+user.username);
							this.sendStep2Email(user.email,user.facebookGroups);


						}  
						
						console.log("CHECK 3");
						if (user.hasEmailBeenActivated && user.setup1IngestPostsCompleted && user.setup2IdentifyGroupsCompleted && user.setup2serverSetupEmailSentDate !== '' && user.setup3serverSetupCompleted && user.setup3serverSetupEmailSentDate === '')
						{
							console.log("Send Step 3 Email:"+user.username);
							this.sendStep3Email(user.email);

						}
						*/

					} catch(error)
					{
						console.log("error:");
						console.log(error);
					}

					console.log("-- DONE --");


				 }
				 
				 this.setState({
					result: "Done"		
				});

			})
			.catch((error) => {
				console.log(error);

				if(error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
			});
	}

	otherAction = () => {

		alert("Processing Other Actions");
	}

  //-------
  sendActivationEmail = (email,firstName) => {

	const newUserData = {
		firstName: firstName,
		email:email
	  };
	  

	axios
	.post('/api/initiateActivation', newUserData)
	.then((response) => {

		console.log("Sent activation enail for :"+email);

	})
	.catch((error) => {
	
	console.log("Error sending email:"+error);
	});  

	
  }
 
 
  sendStep1Email = (email) => {

	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	axios
	  .post('/api/sendSetupStep1Email', {
		email: email
	  })
	  .then((response) => {

		//console.log("Setup 1 Email for "+email+ " response:"+JSON.stringify(response));
		console.log("\tSetup 1 Email for "+email);
	   
	  })
	  .catch((error) => {
	
		console.log('Error sending setup 1 email:'+error);

	  });

  }

  sendStep2Email = (email,facebookGroups) => {

	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	axios
	  .post('/api/sendSetupStep2Email', {
		email: email,
		facebookGroups: facebookGroups
	  })
	  .then((response) => {

		//console.log("Setup 2 Email for "+email+ " response:"+JSON.stringify(response));
	   
		console.log("\tSetup 2 Email for "+email);

	  })
	  .catch((error) => {
		
		console.log('Error sending setup 2 email:'+error);

	  });

  }


  sendStep3Email = (email) => {

	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	axios
	  .post('/api/sendSetupStep3Email', {
		email: email
	  })
	  .then((response) => {

		//console.log("Setup 3 Email for "+email+ " response:"+JSON.stringify(response));
	   
		console.log("\tSetup 3 Email for "+email);

	  })
	  .catch((error) => {
	
		console.log('Error sending setup 3 email:'+error);

	  });

  }

  //-----

	render() {

			return (
				
			 <div>
                    <h1>ADMIN</h1>

					{(this.state.isAdminUser) && (
						<div>
							<br/>
						
							{(this.state.action ==='' || this.state.action ==null) && (
								<div>
									<div style={{fontSize:'22px'}}>

										<u>You need specify an action on the query string</u>:<br/>

										<ul>
											<li><span style={{color:'red'}}>?action=email</span>  - <b>Sends process cycle emails</b></li>

											<li><span style={{color:'red'}}>?action=[other]</span>  - <b>Some other action</b></li>
										</ul>

									</div>
								</div>

							)}			

							{(this.state.action !=='' && this.state.action !=null) && (
								<div>
									
									<h2>Processing action: {this.state.action}</h2>

									<br></br><br></br>
									<h3 style={{color:'red',fontWeight:'bold'}}>{this.state.result}</h3>
								</div>

							)}			



						</div>
					)}


					{(!this.state.isAdminUser) && (
						<div>
							<br/><br/>
							<h2 style={{color:'red'}}>{this.state.message}</h2>
						</div>
					)}


			  </div>
			);
	
	}
}




export default withMediaQuery()(withStyles(styles) (home));
