import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

import appLogo from '../images/logo-nocircle.png';

import CheckoutForm from "../components/checkoutFormSignUp";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { parseOutFirstName } from '../util/utilities';

import axios from 'axios';

const { stripePublishablecKey } = require('../util/constants');

const stripePromise = loadStripe(stripePublishablecKey);


const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#ff642d'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstName: '',
			name: '',
		  	blogUrl: '',
			type: '',
          	email: '',
			password: '',
			errors: [],
			loading: false
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		const firstName= parseOutFirstName(this.state.name);
		this.setState({ loading: true });
		this.setState({ firstName: firstName });

		const newUserData = {
			firstName: firstName,
			name: this.state.name,
			blogUrl: this.state.blogUrl,
			type: this.state.type,
            email: this.state.email,
			password: this.state.password
		};
		axios
			.post('/api/signup', newUserData)
			.then((response) => {
				localStorage.setItem('AuthToken', `${response.data.token}`);
				this.setState({ 
					loading: false,
				});
				
				axios
				.post('/api/initiateActivation', newUserData)
				.then((response) => {
			
				  localStorage.setItem('ActivationFirstName', `${newUserData.firstName}`);
				  localStorage.setItem('ActivationEmail', `${newUserData.email}`);

				  this.props.history.push('/activate');
			
				})
				.catch((error) => {
					
					console.log("Error sending email:"+error);
				});  

			})
			.catch((error) => {
				this.setState({
					errors: error.response.data,
					loading: false
				});
			});
	};

	
	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#ff642d' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
				<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/>
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#ff642d',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
						Traffic Infuser
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					
					
					*** This service works best when you have at least 6 posts
					<br/><br/>
					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
						
							<Grid item xs={12}>
								<Elements stripe={stripePromise}>

								<CheckoutForm targetAction="Subscribe"/>

								</Elements>
							</Grid>
					
					
						</Grid>
					
					<br/>
						<Grid container justify="flex-end">
							<Grid item>
								<Link href="login" variant="body2">
									Already have an account? <strong>Log in</strong>
								</Link>
							</Grid>
						</Grid>
						</ThemeProvider>
					
					<br></br><br></br><br></br>
				</div>
			</Container>
		);
	}
}

export default withStyles(styles)(signup);
