import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardActions, CardContent, Divider, Button, Grid, TextField, Select, InputLabel, MenuItem } from '@material-ui/core';

import clsx from 'clsx';

import axios from 'axios';
import { authMiddleWare } from '../util/auth';

import ConfirmDialog  from '../components/reusableConfirmDialog';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class account extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			country: '',
		    blogUrl: '',
			type: '',

			reason:'',
			message:'',
			alertOpen: false,
			alertMessage: '',
			alertTitle:'',
			uiLoading: true,
			buttonLoading: false,
			imageError: ''
		};
	}

	setAlertOpen = (flag) => {

		this.setState({ alertOpen: flag });
	}
	
	componentWillMount = () => {
	
		this.setState({
			uiLoading: false
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};


	handleReasonChange = (event) => {
		this.setState({
			reason: event.target.value
		});
	};


	submitTicket = (event) => {
		event.preventDefault();
		this.setState({ buttonLoading: true });
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };

		const formRequest= {
			reason: this.state.reason,
			message: this.state.message
		}

		axios
			.post('/api/ticket', formRequest)
			.then(() => {
				this.setState({ buttonLoading: false });

				this.setState({
					alertTitle: 'Successful',
					alertMessage: 'Your ticket has been created. We should be contacting you in 24 to 48 hours.', 
					alertOpen: true,
					reason: '',
					message:''
				});	
			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					window.location.reload();
				}
				console.log(error);
				this.setState({
					buttonLoading: false
				});
			});
	};

	render() {
		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
			
					<h1>Support Tickets</h1>
                    <p>We are here to help, create a support ticket with your inquiry.</p>

					<br />
					<Card {...rest} className={clsx(classes.root, classes)}>
						<form autoComplete="off" noValidate>
							<Divider />
							<CardContent>
								<Grid container spacing={3}>
									
								<Grid item md={8} xs={12}>

										<InputLabel id="reason-select-label">Reason</InputLabel>
										<Select
											labelId="reason-select-label" 
											label="Reason" 
											type="regular"
											variant="outlined"
											value={this.state.reason} 
											style={{width:'75%',height:'40px'}}
											onChange={this.handleReasonChange}
											>
											<MenuItem value={"technical-support"}>Technical Support</MenuItem>
											<MenuItem value={"billing"}>Billing and Payments</MenuItem>
											<MenuItem value={"other"}>Other</MenuItem>
										</Select>
										<br></br>
										<TextField
											fullWidth
											label="Message"
											margin="dense"
											name="message"
											type="regular"
											variant="outlined" 
											multiline={true} 
											rows={10}
											value={this.state.message}
											onChange={this.handleChange}
										/>
									</Grid>

								</Grid>
							</CardContent>
							<Divider />
							<CardActions>
									<Button
										color="primary"
										variant="contained"
										type="submit" 
										style={{color:'#FFFFFF'}}
										className={classes.submitButton}
										onClick={this.submitTicket}
										disabled={
											this.state.buttonLoading ||
											!this.state.reason ||
											!this.state.message
										}
									>
										Submit Ticket
										{this.state.buttonLoading && <CircularProgress size={30} className={classes.progess} />}
									</Button>

							</CardActions>
						</form>
					</Card>


					
					<ConfirmDialog
						title={this.state.alertTitle}
						open={this.state.alertOpen}
						setOpen={this.setAlertOpen}
					>
						{this.state.alertMessage}
					</ConfirmDialog>
				</main>
			);
		}
	}
}


export default withStyles(styles)(account);
