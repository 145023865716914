import React, { Component } from 'react';
import axios from 'axios';

import Account from '../components/account';
import Post from '../components/post';
import Match from '../components/match';
import Subscription from '../components/subscription';
import Support from '../components/support';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SupportIcon from '@material-ui/icons/ContactSupport';
import NotesIcon from '@material-ui/icons/Notes';
import Avatar from '@material-ui/core/avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';

import { authMiddleWare } from '../util/auth'
import appLogo from '../images/logo.png';

import numberOneImage from '../images/1.png';
import numberTwoImage from '../images/2.png';
import numberThreeImage from '../images/3.png';

import inprogressStatusImage from '../images/inprogressStatus.jpg';
import completeStatusImage from '../images/completeStatus.jpg';
import pendingStatusImage from '../images/pendingStatus.jpg';

import whiteLogo from '../images/logo-nocircle-white.png';

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { withMediaQuery } from './withMediaQuery';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		display: "flex"
	  },
	  appBar: {
		zIndex: theme.zIndex.drawer + 1
	  },
	  drawer: {
		flexShrink: 0,
		width: drawerWidth
	  },
	  drawerPaper: {
		width: drawerWidth
	  },
	  menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("md")]: {
		  display: "none"
		}
	  },
	  toolbar: {
		...theme.mixins.toolbar,
		[theme.breakpoints.down("sm")]: {
		  display: "none"
		}
	  },
	  content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(1)
	  }
});



class home extends Component {
	state = {
		render: false
	};
	
	loadSocialMatchesPage = (event) => {
		this.setState({ menuItem: 1 });
		localStorage.setItem('menuItem', 1);
	};

	loadPostPage = (event) => {
		this.setState({ menuItem: 2 });
		localStorage.setItem('menuItem', 2);
	};

	loadAccountPage = (event) => {
		this.setState({ menuItem: 3 });
		localStorage.setItem('menuItem', 3);
	};

	loadSubscriptionPage = (event) => {
		this.setState({ menuItem: 4 });
		localStorage.setItem('menuItem', 4);
	};

	loadSupportPage = (event) => {
		this.setState({ menuItem: 5 });
		localStorage.setItem('menuItem', 5);
	};

	logoutHandler = (event) => {
		localStorage.removeItem('AuthToken');
		this.props.history.push('/login');
	};

	constructor(props) {
		super(props);

		if (localStorage.getItem('menuItem') == null || localStorage.getItem('menuItem')==='')
		{
			localStorage.setItem('menuItem', 1);
		}

		this.state = {
			firstName: '',
			lastName: '',
			profilePicture: '',
			uiLoading: true,
			imageLoading: false,
			openSetupDialog: false,
			open : false,
			menuItem : parseInt(localStorage.getItem('menuItem'),10)
		};
	}

	
	closeSetupDialog = () => {

		this.setState({openSetupDialog: false});
	}

	showSetupDialog = (flag) => {

		this.setState({ openSetupDialog: flag });
	}

	componentWillMount = () => {

		if (localStorage.getItem('menuItem') == null || localStorage.getItem('menuItem')==='')
		{
			localStorage.setItem('menuItem', 1);
		}

		this.setState({ menuItem: parseInt(localStorage.getItem('menuItem'),10)});

		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				//console.log(response.data);
				this.setState({
					firstName: response.data.userCredentials.firstName,
					lastName: response.data.userCredentials.lastName,
					email: response.data.userCredentials.email,
					phoneNumber: response.data.userCredentials.phoneNumber,
					country: response.data.userCredentials.country,
					username: response.data.userCredentials.username,
					hasEmailBeenActivated:response.data.userCredentials.hasEmailBeenActivated,
					setup1IngestPostsCompleted:response.data.userCredentials.setup1IngestPostsCompleted,	
					setup2IdentifyGroupsCompleted:response.data.userCredentials.setup2IdentifyGroupsCompleted,
					setup3serverSetupCompleted:response.data.userCredentials.setup3serverSetupCompleted,
					exemptFlag:response.data.userCredentials.exemptFlag,
					uiLoading: false,
					profilePicture: response.data.userCredentials.imageUrl
				});

				//console.log("exemptFlag:"+response.data.userCredentials.exemptFlag);

				if (!this.state.hasEmailBeenActivated)
				{
					this.props.history.push('/activate');
				} else
				{

					if (response.data.userCredentials.stripeSubscriptionId && response.data.userCredentials.stripeSubscriptionId != '')
					{
						//load subscription
						axios
						.post('/api/subscription', {
							subscriptionId: response.data.userCredentials.stripeSubscriptionId
						})
						.then((response) => {

							const subscriptionStatus = response.data.status;

							//update user subscription status
							axios
							.post('/api/user', {
								stripeSubscriptionStatus: subscriptionStatus
							})
							.then((response) => {

								//Do nothing

							})
							.catch((error) => {
							alert("Error creating your subscription. Try again later.");
							window.location.reload(false);

							console.log('Error in updating customer record:'+error);
					
							});

			
						})
						.catch((error) => {
							alert("An error occurred while retrieving subscription:"+error);
							console.log(error);
						});
					}

					if (!response.data.userCredentials.setup3serverSetupCompleted)
					{
						this.showSetupDialog(true);
					}
				}
			})
			.catch((error) => {
				if (error && error.response && error.response.status && error.response.status === 403) {
					this.props.history.push('/login')
				}
				console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	};

	render() {

		const themeCustom = createTheme({
			palette: {
			  primary: {
				main: '#ff642d' //your color
			  }
			}
		  });

		const { classes, theme } = this.props
		

		let displaySetupHeader = '';
		let displaySetupDevider = '';
		let alignSetupHeader = '';
		
		if (this.props.isDesktop)
		{
			displaySetupHeader = '';
			displaySetupDevider = 'none';
			alignSetupHeader = 'left';
		} else
		{
			displaySetupDevider = '';
			displaySetupHeader = 'none';
			alignSetupHeader = 'center';
		}

		const toggleDrawer = event => {
			if (
			  event.type === "keydown" &&
			  (event.key === "Tab" || event.key === "Shift")
			) {
			  return;
			}
		
			this.setState({ open: !this.state.open });
		  };

		 
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.root}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			return (
				
				<div className={classes.root}>
				<ThemeProvider theme={themeCustom}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
				  <Toolbar>
					<IconButton
					  color="inherit"
					  aria-label="open drawer"
					  edge="start"
					  onClick={toggleDrawer}
					  className={classes.menuButton}
					>
					  <MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#FFFFFF'}}>
					  		<img src={whiteLogo} style={{height:"24px"}}/> Traffic Infuser
					  	</div>
					</Typography>
				  </Toolbar>
				</AppBar>
				<Drawer
				  className={classes.drawer}
				  variant={this.props.isDesktop ? "permanent" : "temporary"}
				  classes={{
					paper: classes.drawerPaper
				  }}
				  anchor="left"
				  open={this.state.open}
				  onClose={toggleDrawer}
				>
				  <div className={classes.toolbar} />
				  <Divider />
				  <br></br>
						<center>
							<Avatar src={appLogo} className={classes.avatar} />
							<p>
								{' '}
								{this.state.firstName} {this.state.lastName}
							</p>
						</center>
				  <Divider />

				  <List>
				  			<ListItem button key="SocialMatches" onClick={this.loadSocialMatchesPage}>
								<ListItemIcon>
									{' '}
									<AssignmentTurnedInIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Social Matches" />
							</ListItem>

							<ListItem button key="Post" onClick={this.loadPostPage}>
								<ListItemIcon>
									{' '}
									<NotesIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Your Posts" />
							</ListItem>

							{!this.state.exemptFlag &&
							<ListItem button key="Account" onClick={this.loadAccountPage}>
								<ListItemIcon>
									{' '}
									<AccountBoxIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Account" />
							</ListItem>
							}

							{!this.state.exemptFlag &&
							<ListItem button key="Subscription" onClick={this.loadSubscriptionPage}>
								<ListItemIcon>
									{' '}
									<SubscriptionsIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Subscriptions" />
							</ListItem>
							}

							<ListItem button key="Support" onClick={this.loadSupportPage}>
								<ListItemIcon>
									{' '}
									<SupportIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Support" />
							</ListItem>

							<ListItem button key="Logout" onClick={this.logoutHandler}>
								<ListItemIcon>
									{' '}
									<ExitToAppIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</ListItem>
					</List>
			
				 
				</Drawer>
				<main className={classes.content}>
					
					{ this.state.menuItem ===1 && <Match/>}
					{ this.state.menuItem ===2 && <Post/>}
					{ this.state.menuItem ===3 && !this.state.exemptFlag && <Account/>}
					{ this.state.menuItem ===4 && !this.state.exemptFlag && <Subscription/>}
					{ this.state.menuItem ===5 && <Support/>}
				
				</main>
				</ThemeProvider>


				{/*  SETUP IN PROGRESS DIALOG  */}	
				<Dialog open={this.state.openSetupDialog} onClose={this.closePaymentDialog} aria-labelledby="form-dialog-title">
				<DialogTitle><div style={{color:'#ff642d',textAlign:'center',fontSize:'24px',fontWeight:'bold'}}>Your Setup Is In Progress...</div></DialogTitle>
				<DialogContent>
				
					<div style={{fontSize:'16px'}}>We are currently setting up your service specifically for your blog.</div>
					
					<div style={{paddingTop:'2px',fontSize:'16px'}}>Below are the <strong>one-time</strong> tasks we are completing for your setup (<i>You don't have to do anything</i>):</div>

					<br/>
					<Grid container spacing={2}  style={{fontWeight:'bold',fontSize:'16px',display:displaySetupHeader }} >
							<Grid item xs={12} sm={1}>
								<u>#</u>
							</Grid>
							<Grid item xs={12} sm={4}>
								<u>Task</u>
							</Grid>
							<Grid item xs={12} sm={3} style={{textAlign:'center'}}>
								<u>Duration</u>
							</Grid>
							<Grid item xs={12} sm={4}  style={{textAlign:'center'}}>
								<u>Status</u>
							</Grid>
					</Grid>

					<Grid container spacing={2} style={{textAlign:alignSetupHeader}}>
							<Grid item xs={12} sm={1}>
								<img src={numberOneImage} style={{height:'22px'}}/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div style={{color:'#ff642d',fontWeight:'bold',fontSize:'18px'}}>Ingest Posts</div>
								<div>Our programmers need to write a program to identify all the posts from your blog.</div>

							</Grid>
							<Grid item xs={12} sm={3} style={{textAlign:'center'}}>
								24-48 hrs
							</Grid>
							<Grid item xs={12} sm={4} style={{textAlign:'center'}}> 
								
							{(!this.state.setup1IngestPostsCompleted) && ( 
								<span>
									<img src={inprogressStatusImage} style={{height:'37px'}}/>

									<div style={{fontWeight:'bold',color:'#f5ac1b'}}>In Progress</div>
								</span>
							)}

							{(this.state.setup1IngestPostsCompleted) && ( 
								<span>
									<img src={completeStatusImage} style={{height:'37px'}}/>

									<div style={{fontWeight:'bold',color:'#90b838'}}>Done</div>
								</span>
							)}

							</Grid>
					</Grid>

					<hr style={{display:displaySetupDevider }}/>

					<Grid container spacing={2} style={{textAlign:alignSetupHeader}}>
							<Grid item xs={12} sm={1}>
								<img src={numberTwoImage} style={{height:'22px'}}/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div style={{color:'#ff642d',fontWeight:'bold',fontSize:'18px'}}>Identify Groups</div>
								<div>Our team will analyze your blog and identify the right social media groups and communities related to it.</div>

							</Grid>
							<Grid item xs={12} sm={3} style={{textAlign:'center'}}>
								24 hrs
							</Grid>
							<Grid item xs={12} sm={4} style={{textAlign:'center'}}> 
								
								{(!this.state.setup2IdentifyGroupsCompleted && !this.state.setup1IngestPostsCompleted) && ( 
									<span>
										<img src={pendingStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#02aff1'}}>Pending</div>
									</span>
								)}
								
								{(!this.state.setup2IdentifyGroupsCompleted && this.state.setup1IngestPostsCompleted) && ( 
									<span>
										<img src={inprogressStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#f5ac1b'}}>In Progress</div>
									</span>
								)}

								{(this.state.setup2IdentifyGroupsCompleted) && ( 
									<span>
										<img src={completeStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#90b838'}}>Done</div>
									</span>
								)}

							
							</Grid>
					</Grid>

					<hr style={{display:displaySetupDevider }}/>

					<Grid container spacing={2} style={{textAlign:alignSetupHeader}}>
							<Grid item xs={12} sm={1}>
								<img src={numberThreeImage} style={{height:'22px'}}/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div style={{color:'#ff642d',fontWeight:'bold',fontSize:'18px'}}>Get Traffic Matches</div>
								<div>That's it, you're all set. </div>

							</Grid>
							<Grid item xs={12} sm={3} style={{textAlign:'center'}}>
								On Going
							</Grid>
							<Grid item xs={12} sm={4} style={{textAlign:'center'}}> 
								
								{(!this.state.setup3serverSetupCompleted && this.state.setup2IdentifyGroupsCompleted) && ( 
									<span>
										<img src={inprogressStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#f5ac1b'}}>In Progress</div>
									</span>
								)}

								{(!this.state.setup3serverSetupCompleted  && !this.state.setup2IdentifyGroupsCompleted) && ( 
									<span>
										<img src={pendingStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#02aff1'}}>Pending</div>
									</span>
								)}

								{(this.state.setup3serverSetupCompleted) && ( 
									<span>
										<img src={completeStatusImage} style={{height:'37px'}}/>

										<div style={{fontWeight:'bold',color:'#90b838'}}>Done</div>
									</span>
								)}



							</Grid>
					</Grid>
				<br></br>

				<div style={{fontSize:'16px'}}>Once the setup is complete, the social media traffic matches will start appearing on your dashboard. You will also receive emails notifying you of new social media traffic matches.</div>

				</DialogContent>
				<DialogActions>

				<Button
						color="primary"
						variant="contained"
						type="submit" 
						style={{color:'#FFFFFF',backgroundColor:'#ff642d'}}
						onClick={this.closeSetupDialog}>
					OK
				</Button>
			
				</DialogActions>
			</Dialog>						


			  </div>
			);
		}
	}
}




export default withMediaQuery()(withStyles(styles) (home));
