import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from "@material-ui/core/styles";

export const withMediaQuery = () => Component => props => {

  const theme = useTheme();  
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return <Component isDesktop={isDesktop} {...props} />
  
}


