import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import {createTheme} from '@material-ui/core/styles';

import appLogo from '../images/logo-nocircle.png';

import ConfirmDialog  from '../components/reusableConfirmDialog';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#ff642d'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activated: false,
			errors: [],
			alertOpen: false,
			alertMessage: '',
			alertTitle:'',
			loading: false
		};
	}

	setAlertOpen = (flag) => {

		this.setState({ alertOpen: flag });
	}

	componentWillMount = () => {

		const search = this.props.location.search;    
		let token = new URLSearchParams(search).get("token");

		if (token != null)
		{
			token = token.split(' ').join('+');
			//alert(token);
			
			axios
				.post('/api/isActivation', {token: token})
				.then((response) => {
			
				  if (response.data.valid && response.data.valid ==true)
				  {
						axios
						.post('/api/completeActivation', {token: token})
						.then((response) => {

						console.log(JSON.stringify(response.data));
							
						if (response.data.status && response.data.status ==='success')
						{
							this.setState({
								activated: true
							});
					
						}else
						{

							this.setState({
								alertTitle: 'Activation Error',
								alertMessage: 'An error occurred whiles activating your account. Please try again.', 
								alertOpen: true
							});		
						}
		
						})
						.catch((error) => {

							this.setState({
								alertTitle: 'Activation Error',
								alertMessage: 'An error occurred whiles activating your account. Please try again.', 
								alertOpen: true
							});		

							console.log("Error completing activation:"+error);
						});  
				  } else 
				  {
					
					this.setState({
						alertTitle: 'Activation Expired',
						alertMessage: 'An error occurred whiles activating your account. You activation has expired, sign up again.', 
						alertOpen: true
					});		


				  }

			
				})
				.catch((error) => {
					this.setState({
						alertTitle: 'Activation Expired',
						alertMessage: 'An error occurred whiles activating your account. You activation has expired, sign up again.', 
						alertOpen: true
					});		
				});  
		}

	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}

	handleResendEmail = async event => {

		event.preventDefault();

		const newUserData = {
			email:localStorage.getItem('ActivationEmail'),
			firstName:localStorage.getItem('ActivationFirstName')
		}

		axios
		.post('/api/initiateActivation', newUserData)
		.then((response) => {
	
			alert("A new activation email has been sent to you, please check your email");
	
		})
		.catch((error) => {
		
			console.log("Error sending email:"+error);
		});  

		
	}

	handleGotoLogin = async event => {

		event.preventDefault();

		window.location.href = '/login';
	}

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#ff642d' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
				<img src={appLogo} style={{height:'45px'}}/>
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#ff642d',fontSize:'20px'}}>
					  		Traffic Infuser
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>

					{(!this.state.activated) && (
						<div>
							{(localStorage.getItem('ActivationEmail') != null && localStorage.getItem('ActivationEmail') != '') && (
							<div>
									<br></br>

									<h1>Almost there, {localStorage.getItem('ActivationFirstName')}!</h1>
								
									<br></br>
									<div style={{fontSize : '20px',color:'4f5053',textAlign:'center'}}>
									To activate your account, go the email we've just sent you to <strong>{localStorage.getItem('ActivationEmail')}</strong>
									</div>


									<br></br><br></br>
									<div style={{fontSize : '18px',color:'4f5053',textAlign:'center'}}>
									Didn't get the email? <a href="#" onClick={this.handleResendEmail} style={{textDecoration:'none', color:'#ff6020'}}>We'll send it again</a>
									</div>

							</div>		
							)}

							{(localStorage.getItem('ActivationEmail') == null || localStorage.getItem('ActivationEmail') === '') && (
							<div>
									<br></br>

									<div style={{textAlign:'center'}}>
										<h1>Almost there!</h1>
									</div>
								
									<br></br>
									<div style={{fontSize : '20px',color:'4f5053',textAlign:'center'}}>
									To activate your account, open the email we sent you when you signed up and click the activate button.
									</div>

							</div>		
							)}
						
						 </div>		
						)}	


						{(this.state.activated) && (
						   <div>

<							div style={{textAlign:'center'}}>
										<h1>Your account has been successfully activated!</h1>
									</div>
								
									<br></br>
									<div style={{fontSize : '20px',color:'4f5053',textAlign:'center'}}>
									You may <a href="#" onClick={this.handleGotoLogin} style={{fontSize:'17px'}}>login</a> now.
									</div>

						   </div>		
						)}		

					<ConfirmDialog
						title={this.state.alertTitle}
						open={this.state.alertOpen}
						setOpen={this.setAlertOpen}
					>
						{this.state.alertMessage}
					</ConfirmDialog>

				</div>
			</Container>
		);
	}
}

export default withStyles(styles)(signup);
